import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"
import { wrapSup } from "../utils/utils"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import GetStarted from "../components/CTA/GetStarted"

const FinancialQueryPage = (props) => {
  var pageContext = props.pageContext
  var location = props.location
  var data = props.data
  const post = data.allUniquePagesJson.nodes[0]

  let language = "en"

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          schemaPage={post.schemaPage ? post.schemaPage : null}
          pathname={location.pathname}
        />
        <div className="joshua-tree-content">
          <div className="columns">
            <div className="column">
              <div className="columns p-section text-section is-mobile financial-policy">
                <div className="column is-4"></div>
                <div className="column is-16">
                  <h1>{post.heading}</h1>

                  <MarkdownViewer markdown={post.topBlurb} />

                  <div className="payment-list">
                    {post.paymentMethod.map((office, i) => (
                      <div className="payment-box" key={i}>
                        <h5>{office.heading}</h5>
                        <MarkdownViewer markdown={office.body} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="column is-4"></div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="columns p-section color-back text-section is-mobile finance-section">
                <div className="column is-4"></div>
                <div className="column is-16">
                  <div>
                    <MarkdownViewer markdown={post.body} />

                    <div className="finance-card-container">
                      {post.cards.map((card, i) => (
                        <div className="finance-card" key={i}>
                          <div className="finance-card-text">
                            <h5
                              dangerouslySetInnerHTML={{
                                __html: wrapSup(card.name),
                              }}
                            ></h5>
                            <MarkdownViewer markdown={card.blurb} />
                          </div>
                          <div className="finance-card-button">
                            <ButtonGroupMap isCentered buttons={card.buttons} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="column is-4"></div>
                </div>
              </div>
            </div>
          </div>

          <GetStarted
            className="get-started-cta"
            sideColumnIs={4}
            headingSideColumnIs={4}
            centerHeading
            centerText
            heading={post.getStarted.heading}
            paragraph={<p>{post.getStarted.blurb}</p>}
            buttons={post.getStarted.buttons}
          />
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query FinancialQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        body
        topBlurb
        paymentMethod {
          heading
          body
        }
        cards {
          name
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        getStarted {
          heading
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        name
        metaTitle
        metaDescription
        hasTopSection
        topText
        body
        manual_file
      }
    }
  }
`

export default FinancialQueryPage
